import React from "react"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"

import StaticPage from "../../components/StaticPage/StaticPage"
import { createOverlay, createContentList } from "../../contentfunctions/faq.js"

import Carousel1 from "../../images/faq/faq-min.jpeg"

const FAQPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage("es", "/faq", location.state)
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(data.faqContent.item.content)
        const img = {
          src: Carousel1,
          altText: contentMap.seo_title.value,
          caption: contentMap.seo_description.value,
        }
        return (
          <StaticPage
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            img={img}
            originPage="faq"
            language="es"
            location={location}
            contentMap={contentMap}
            commonContentMap={commonContentMap}
            breadCrumbs={["menu_home", "menu_faq"]}
            createContentFun={createContentList}
            createOverlayFun={createOverlay}
            keywords={Utils.GetKeywordsFromTags(data.faqContent.item.tags)}
          />
        )
      }}
    />
  )
}

export default FAQPage

const pageQuery = graphql`
  {
    faqContent: faqContentEses {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentEses {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
